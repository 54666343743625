import { render, staticRenderFns } from "./AppDialog.vue?vue&type=template&id=16973691&scoped=true&"
import script from "./AppDialog.vue?vue&type=script&lang=js&"
export * from "./AppDialog.vue?vue&type=script&lang=js&"
import style0 from "./AppDialog.vue?vue&type=style&index=0&lang=css&"
import style1 from "./AppDialog.vue?vue&type=style&index=1&id=16973691&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16973691",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDialog,VIcon,VRow})
