export class AnsuranClaimSubmission {
  id = ''
  referenceNumber = ''
  applicationDate = ''
  ansuranStatus = ''
  user = ''
  productName = ''
  productModel = ''
  productSerialNumber = ''
  eMandate = ''
  creditAccountNumber = ''
  consignmentNumber = ''
  firstMonthPayment = ''
  initialPayment = ''
  documentConsignmentNumber = ''
  remarks = ''
  creditPrice = ''
  installmentTerm = ''
  pco = ''
  staffClaimAmount = ''
  customerName = ''
  nric = ''
  phone = ''
  email = ''
  addressLine1 = ''
  addressLine2 = ''
  addressLine3 = ''
  city = ''
  postcode = ''
  state = ''
  country = ''
  pcoCategory = ''
  ansuranApplicationFirm = ''

  constructor({
    id = null,
    referenceNumber = null,
    applicationDate = null,
    ansuranStatus = null,
    user = null,
    productName = null,
    productModel = null,
    productSerialNumber = null,
    eMandate = null,
    creditAccountNumber = null,
    consignmentNumber = null,
    firstMonthPayment = null,
    initialPayment = null,
    documentConsignmentNumber = null,
    remarks = null,
    creditPrice = null,
    installmentTerm = null,
    pco = null,
    staffClaimAmount = null,
    customerName = null,
    nric = null,
    phone = null,
    email = null,
    addressLine1 = null,
    addressLine2 = null,
    addressLine3 = null,
    city = null,
    postcode = null,
    state = null,
    country = null,
    pcoCategory = null,
    ansuranApplicationFirm = null
  } = {}) {
    this.id = id
    this.referenceNumber = referenceNumber
    this.applicationDate = applicationDate
    this.ansuranStatus = ansuranStatus
    this.user = user
    this.productName = productName
    this.productModel = productModel
    this.productSerialNumber = productSerialNumber
    this.eMandate = eMandate
    this.creditAccountNumber = creditAccountNumber
    this.consignmentNumber = consignmentNumber
    this.firstMonthPayment = firstMonthPayment
    this.initialPayment = initialPayment
    this.documentConsignmentNumber = documentConsignmentNumber
    this.remarks = remarks
    this.creditPrice = creditPrice
    this.installmentTerm = installmentTerm
    this.pco = pco
    this.pcoCategory = pcoCategory
    this.staffClaimAmount = staffClaimAmount
    this.customerName = customerName
    this.nric = nric
    this.phone = phone
    this.email = email
    this.addressLine1 = addressLine1
    this.addressLine2 = addressLine2
    this.addressLine3 = addressLine3
    this.city = city
    this.postcode = postcode
    this.state = state
    this.country = country
    this.ansuranApplicationFirm = ansuranApplicationFirm
  }
}
