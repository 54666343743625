export const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  DASHBOARD: 'dashboard',
  ANSURAN_DASHBOARD: 'ansuran-dashboard',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  LOGOUT: 'logout',
  ERROR: 'error',
  //Agent
  AGENT_LIST: 'agent-list',
  AGENT_CREATE: 'agent-create',
  AGENT_DETAILS: 'agent-details',
  //User
  USER_LIST: 'user-list',
  USER_CREATE: 'user-create',
  USER_DETAILS: 'user-details',
  USER_RESET_PASSWORD: 'user-reset-password',
  //Role
  ROLE_LIST: 'role-list',
  ROLE_CREATE: 'role-create',
  ROLE_DETAILS: 'role-details',
  //Product
  PRODUCT_LIST: 'product-list',
  PRODUCT_CREATE: 'product-create',
  PRODUCT_DETAILS: 'product-details',
  //Application Types
  APPLICATION_TYPE_LIST: 'application-type-list',
  APPLICATION_TYPE_CREATE: 'application-type-create',
  APPLICATION_TYPE_DETAILS: 'application-type-details',
  //Company
  COMPANY_LIST: 'company-list',
  COMPANY_CREATE: 'company-create',
  COMPANY_DETAILS: 'company-details',
  //Bank
  BANK_LIST: 'bank-list',
  BANK_CREATE: 'bank-create',
  BANK_DETAILS: 'bank-details',
  //Commission Rate
  COMMISSION_RATE_LIST: 'commission-rate-list',
  COMMISSION_RATE_CREATE: 'commission-rate-create',
  COMMISSION_RATE_DETAILS: 'commission-rate-details',
  //Ansuran Commission Rate
  ANSURAN_COMMISSION_RATE_LIST: 'ansuran-commission-rate-list',
  ANSURAN_COMMISSION_RATE_CREATE: 'ansuran-commission-rate-create',
  ANSURAN_COMMISSION_RATE_DETAILS: 'ansuran-commission-rate-details',
  //Customer Application
  CUSTOMER_APPLICATION_LIST: 'customer-application-list',
  CUSTOMER_APPLICATION_CREATE: 'customer-application-create',
  CUSTOMER_APPLICATION_DETAILS: 'customer-application-details',
  APPLICATION_HISTORY: 'application-history',
  APPLICATION_HISTORY_DETAILS: 'application-history-details',
  //Ansuran Application
  ANSURAN_APPLICATION_LIST: 'ansuran-application-list',
  ANSURAN_APPLICATION_CREATE: 'ansuran-application-create',
  ANSURAN_APPLICATION_DETAILS: 'ansuran-application-details',
  ANSURAN_APPLICATION_HISTORY: 'ansuran-application-history',
  ANSURAN_APPLICATION_HISTORY_DETAILS: 'ansuran-application-history-details',
  //Domain
  DOMAIN_LIST: 'domain-list',
  DOMAIN_CREATE: 'domain-create',
  DOMAIN_DETAILS: 'domain-details',
  //Claim Submission
  CLAIM_SUBMISSION_LIST: 'claim-submission-list',
  CLAIM_SUBMISSION_DETAILS: 'claim-submission-details',
  //Claim Payout
  CLAIM_PAYOUT_LIST: 'claim-payout-list',
  CLAIM_PAYOUT_DETAILS: 'claim-payout-details',
  CLAIM_PAYOUT_HISTORY: 'claim-payout-history',
  //Claim Payout
  CLAIM_HISTORY_LIST: 'claim-history-list',
  CLAIM_HISTORY_DETAILS: 'claim-history-details',
  //Claim Reconcile
  CLAIM_RECONCILE_LIST: 'claim-reconcile-list',
  CLAIM_RECONCILE_DETAILS: 'claim-reconcile-details',
  //Ansuran Claim Submission
  ANSURAN_CLAIM_SUBMISSION_LIST: 'ansuran-claim-submission-list',
  ANSURAN_CLAIM_SUBMISSION_DETAILS: 'ansuran-claim-submission-details',
  //Ansuran Claim Payout
  ANSURAN_CLAIM_PAYOUT_LIST: 'ansuran-claim-payout-list',
  ANSURAN_CLAIM_PAYOUT_DETAILS: 'ansuran-claim-payout-details',
  //Ansuran Claim History
  ANSURAN_CLAIM_HISTORY_LIST: 'ansuran-claim-history-list',
  ANSURAN_CLAIM_HISTORY_DETAILS: 'ansuran-claim-history-details',
  //Ansuran Claim Reconcile
  ANSURAN_CLAIM_RECONCILE_LIST: 'ansuran-claim-reconcile-list',
  ANSURAN_CLAIM_RECONCILE_DETAILS: 'ansuran-claim-reconcile-details',
  //Ansuran Report
  ANSURAN_REPORT_SALES_TRANSACTION: 'ansuran-report-sales-transaction',
  //Report
  REPORT_CLAIM_SUBMISSION: 'report-claim-submission',
  REPORT_CLAIM_PAYOUT: 'report-claim-payout',
  REPORT_SALES_TRANSACTION: 'report-sales-transaction'
})
